<template>
  <div v-editable="props.blok" class="wp-media" :data-uid="props.blok._uid">
    <PflegeMedia :slides="props.blok.asset">
      <template #default="{ slide }">
        <div v-if="slide.component === 'WPElementImage'">
          <NuxtImg
            format="webp"
            sizes="sm:100vw md:810px xl:1170px"
            placeholder
            :src="slide.content.filename"
            :alt="slide.content.alt"
            :title="slide.content.title"
            :data-copyright="slide.content.copyright"
            :modifiers="{ smart: true }"
            quality="90"
            fit="in"
            loading="lazy"
            :style="{
              '--imageAspectRatioDesktop': aspectRatioVariants.desktop,
              '--imageAspectRatioTablet': aspectRatioVariants.tablet,
              '--imageAspectRatioMobile': aspectRatioVariants.mobile,
              'border-radius': props.blok.roundCorners ? '10px' : '',
              ...getFocalPercent?.(slide.content)
            }"
          />
        </div>
        <div v-else-if="slide.component === 'Video'">
          <video controls :src="slide.video.filename" :poster="slide.thumbnail.filename">
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      </template>
    </PflegeMedia>
  </div>
</template>

<script setup>
import { PflegeMedia } from '@shared'
import { computed } from 'vue'

/**
 * @typedef {object} AspectRatio
 * @property {string} desktop
 * @property {string} tablet
 * @property {string} mobile
 */
const props = defineProps({
  blok: {
    type: Object,
    required: true,
    default: () => {}
  },
  aspectRatio: {
    type: [Object, String],
    required: true
  }
})

const aspectRatioVariants = computed(() => {
  if (typeof props.aspectRatio === 'object') {
    return props.aspectRatio
  } else if (typeof props.aspectRatio === 'string') {
    return {
      desktop: props.aspectRatio,
      tablet: props.aspectRatio,
      mobile: props.aspectRatio
    }
  } else {
    return {
      desktop: 'initial',
      tablet: 'initial',
      mobile: 'initial'
    }
  }
})

const getFocalPercent = (content) => {
  if (!content.focus) {
    return null
  }

  const [width, height] = content.filename.split('/')[5].split('x')
  const [x, y] = content.focus.split(':')[0].split('x')
  const imageXPosition = (x * 100) / width
  const imageYPosition = (y * 100) / height

  return {
    '--imageXPosition': imageXPosition + '%',
    '--imageYPostion': imageYPosition + '%'
  }
}
</script>

<style scoped>
:is(img, video, svg) {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: var(--imageXPosition) var(--imageYPostion);
  aspect-ratio: var(--imageAspectRatioDesktop, unset);

  @media (max-width: 810px) {
    aspect-ratio: var(--imageAspectRatioTablet, var(--imageAspectRatioDesktop));
  }

  @media (max-width: 576px) {
    aspect-ratio: var(
      --imageAspectRatioMobile,
      var(--imageAspectRatioTablet, var(--imageAspectRatioDesktop))
    );
  }
}
</style>
